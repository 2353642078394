import React from "react"

import Layout from "../components/layout"
import SeO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SeO title="Politique de Confidentialité" />
    <h1>Politique de Confidentialité</h1>
    Vous trouverez sur cette page l’ensemble des réponses aux questions courantes concernant notre politique concernant la sécurité de vos données personnelles, en accord avec le Règlement Européen sur la Protection des Données (RGPD)

    <h2>1. Qui sommes nous</h2>
    Editeur du site: Lyketil Sàrl, Lausanne, Suisse<br/>
    Hébergeur: Netlify<br/>
    Contact: hello [_at_] video-lab [point] io

    <h2>2. Quelles données personnelles nous collectons, et pourquoi</h2>
    <h3>a. Formulaires de contact</h3>
    Nous collections votre nom et prénom, email, entreprise, numéro de téléphone ainsi que votre demande de prestation afin de pouvoir communiquer avec vous et mieux répondre à vos besoins. Pour conclure un contrat et proposer une facturation par Stripe par exemple, nous sommes obligés de recueillir et traiter des données personnelles.

    <h3>b. Analytics</h3>
    Nous analysons l’utilisation de notre site internet, en recueillant des données de navigation. Ces informations nous permettent d’améliorer notre site internet, nos offres, et le ciblage de nos publicités. Nous utilisons Google Analytics et ActiveCampaigns.

    <h3>c. Cookies</h3>
    Refusez les cookies depuis votre navigateur. Les Cookie Google Adwords et Facebook Ads nous permettent d'adapter nos publicités afin de proposer des campagnes adaptées à vos besoins et vous porposer des offres intéressantes.

    <h2>3. Avec qui sont partagées les données personnelles</h2>
    Nous collaborons uniquement avec des partenaires qui respectent les standards de sécurité maximum. Vos données sont uniquement partagées lorsque nous utilisons des logiciels d’autres parties, pour mieux répondre à vos besoins (CRM), améliorer notre site (analytics) et nos publicités (publicité en ligne).

    <h2>4. Combien de temps nous conservons les données personnelles</h2>
    Nous conservons les données personnelles 3 ans pour nos activités marketing et le suivi de notre clientèle. En ce qui concerne les données personnelles rattachées à la facturation, nous les gardons 6 ans.

    <h2>5. Vos droits sur vos données personnelles</h2>
    Vous avez le contrôle de vos données personnelles. Vous pouvez à tout moment nous écrire pour demander la suppression, modification, ou le transfert de vos données personnelles et nous vous répondrons dans les plus brefs délais.

    <h2>6. Comment vos données sont protégées</h2>
    Toutes nos données sont encryptées (HTTPS/SSL) avant d’être envoyées aux outils marketing que nous utilisons.
    Aucune de vos données personnelles ne sont stockées dans nos bases de données du site internet. Les données sont stockées dans nos logiciels marketing, tous choisis pour leur conformité aux législations concernant la sécurité des données personnelles, ainsi qu’à leur niveau de sécurité.

    Lyketil peut modifier cette politique si les cookies sont obsolètes ou si nous avons de nouveaux besoins. Cette page montre toujours la dernière version. Cette politique a été modifiée en Mai 2018.<br/>
    En cas de question, veuillez nous contacter : hello [_at_] video-lab [point] io
  </Layout>
)

export default PrivacyPolicy
